import React from 'react'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import { Link, useLocation } from 'react-router-dom'
import { useI18n, useAuth } from '../../context'
import { format } from '../../utils'
import { CompanyUserIcon } from '../icons'
import { SidebarUserRoot, TooltipContent } from './SidebarUser.style'
import { getUserId } from '../../services/user'

const SidebarUser = () => {
  const { currentUser } = useAuth()
  const { t } = useI18n()
  const { pathname } = useLocation()

  const isActive = pathname === `/user/${getUserId()}/company`

  if (!currentUser) return null

  const { created_at, group, photo, siren, first_name, last_name, birth_date } =
    currentUser
  const name = currentUser.name || `${first_name} ${last_name}`

  const identifier = siren ? (
    <>
      {`${group && group !== 'default' ? `${group} - ` : ''}`}
      {siren}
    </>
  ) : (
    <Tooltip title={t('common.birth_date')} arrow>
      <Typography
        className="SubTitle"
        variant="body2"
        whiteSpace="nowrap"
        width="fit-content"
      >
        {`${group && group !== 'default' ? `${group} - ` : ''}`}
        {format(birth_date, 'dd MMM yyyy')}
      </Typography>
    </Tooltip>
  )

  return (
    <SidebarUserRoot
      direction="row"
      className={`SidebarUser ${isActive ? 'active' : ''}`}
      spacing="12px"
      alignContent="center"
      component={Link}
      to={`/user/${currentUser.id}/company`}
    >
      <Avatar src={photo} alt={name}>
        <CompanyUserIcon />
      </Avatar>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        flexGrow={1}
        maxWidth="calc(100% - 40px)"
      >
        <Tooltip
          arrow
          title={
            <TooltipContent>
              <p>{name}</p>
              <span>
                {t('common.userAddedAt', {
                  date: format(created_at, 'PPP'),
                })}
              </span>
            </TooltipContent>
          }
        >
          <Typography variant="h6" className="ellipsis Title">
            {name}
          </Typography>
        </Tooltip>
        {identifier && (
          <Typography
            variant="body2"
            color="text.secondary"
            className="ellipsis SubTitle"
            fontSize={12}
          >
            {identifier}
          </Typography>
        )}
      </Box>
    </SidebarUserRoot>
  )
}

export default React.memo(SidebarUser)
