/* eslint-disable max-lines */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import userService from '../../services/user'
import { fromLS } from '../../utils'

const initialState = {
  clientInfo: fromLS('clientInfo', true),
  users: null,
  loading: false,
  userById: { ...fromLS('userById', true) },
  latestUsers: null,
  metrics: null,
  groups: null,
  clientSettings: null,
}

export const getClientInfo = createAsyncThunk(
  'user/getClientInfo',
  userService.getClientInfo
)

export const getClientSettings = createAsyncThunk(
  'user/getClientSettings',
  userService.getClientSettings
)

export const updateClientSettings = createAsyncThunk(
  'user/updateClientSettings',
  userService.updateClientSettings
)

export const generateApiKey = createAsyncThunk(
  'user/generateApiKey',
  userService.generateApiKey
)

export const getApiKey = createAsyncThunk(
  'user/getApiKey',
  userService.getApiKey
)

export const getUsers = createAsyncThunk('user/getUsers', userService.getUsers)

export const getLatestUsers = createAsyncThunk(
  'user/getLatestUsers',
  userService.getUsers
)

export const createUser = createAsyncThunk(
  'user/createUser',
  userService.createUser
)

export const deleteUser = createAsyncThunk(
  'user/deleteUser',
  userService.deleteUser
)

export const getMetrics = createAsyncThunk(
  'user/getMetrics',
  userService.getMetrics
)

export const getGroups = createAsyncThunk(
  'user/getGroups',
  userService.getGroups
)

export const getUser = createAsyncThunk('user/getUser', userService.getUser)

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // Handle synchronous actions here
  },
  extraReducers: {
    [getClientInfo.fulfilled]: (state, action) => {
      const { data, status } = action.payload

      state.clientInfo = data
      localStorage.setItem('clientInfo', JSON.stringify(state.clientInfo))
      state.status = status
    },
    [getClientInfo.rejected]: (state, action) => {
      if (action.error.message === 'Failed to fetch') {
        state.status = 503
      }
    },
    [getLatestUsers.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        data.result = data.result?.map((u) => ({
          ...u,
          name: u.name || `${u.first_name} ${u.last_name}`,
        }))

        state.latestUsers = data
      } else state.latestUsers = null
    },
    [getUsers.pending]: (state) => {
      state.loading = true
    },
    [getUsers.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.loading = false

      if (data) {
        data.result = data.result?.map((u) => ({
          ...u,
          name: u.name || `${u.first_name} ${u.last_name}`,
        }))

        state.users = data

        state.users.result.forEach((u) => {
          state.userById[u.id] = u
        })
      } else state.users = null
    },
    [getLatestUsers.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        data.result = data.result?.map((u) => ({
          ...u,
          name: u.name || `${u.first_name} ${u.last_name}`,
        }))

        state.latestUsers = data

        state.latestUsers.result.forEach((u) => {
          state.userById[u.id] = u
        })
      }
    },
    [createUser.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        data.name = data.name || `${data.first_name} ${data.last_name}`

        if (state.users?.result) {
          state.users.result = [data, ...state.users.result]
        }

        state.latestUsers = {
          ...state.latestUsers,
          result: [data, ...(state.latestUsers?.result || [])],
        }
      }
    },
    [deleteUser.fulfilled]: (state, action) => {
      const { id } = action.meta.arg
      const { data } = action.payload

      if (data === null && state.users?.result) {
        state.users.result = state.users.result.filter((u) => u.id !== id)
      }

      if (data === null && state.latestUsers?.result) {
        state.latestUsers.result = state.latestUsers.result.filter(
          (u) => u.id !== id
        )
      }
    },
    [getUser.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        state.userById[data.id] = data
      }

      localStorage.setItem('userById', JSON.stringify({ [data.id]: data }))
    },
    [getMetrics.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.metrics = data
    },
    [getGroups.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.groups = data?.groups
    },
    [getClientSettings.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.clientSettings = data
    },
    [updateClientSettings.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.clientSettings = data
    },
    [getApiKey.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.apiKey = data
    },
    [generateApiKey.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.apiKey = data
    },
  },
})

export default user.reducer
