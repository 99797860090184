/**
 * Indicates whether NODE_ENV is production
 */
export const isProd = process.env.NODE_ENV === 'production'

/**
 * Indicates whether NODE_ENV is development
 */
export const isDev = process.env.NODE_ENV === 'development'

export const BASE_URL = process.env.REACT_APP_BASE_URL

export const MAX_PAGE_SIZE = 100
export const DEFAULT_PAGE_SIZE = 20
export const LEGAL = 'LEGAL'
export const NATURAL = 'NATURAL'

export const MAP_TOKEN =
  process.env.REACT_APP_MAP_TOKEN ||
  'pk.eyJ1IjoiYnJhdmVjb3ciLCJhIjoiY2o1ODEwdWljMThwbTJ5bGk0a294ZmVybiJ9.kErON3w2kwEVxU5aNa-EqQ'

export const MAP_STYLE =
  process.env.REACT_APP_MAP_STYLE || 'mapbox://styles/mapbox/streets-v9'
// 'https://api.maptiler.com/maps/streets/style.json?key=get_your_own_OpIi9ZULNHzrESv6T2vL'

/**
 * App pages. key is the unique key that identify each page. It is used to load i18n by page.
 * So add it must be unique and must match the its i18n folder like i18n/language/page where
 * page is the unique key of the page. Ex: i18n/fr/home
 */
export const PAGES = [
  {
    pathname: '/',
    key: 'home',
  },
]

export const SIDEMENU_WIDTH = {
  DEFAULT: 260,
  COMPACT: 65,
}

export const SUPPORT_EMAIL = 'support@qardapi.com'

export const providerCategories = {
  Institutional: { index: 1, category: 'Institutional' },
  Financial: { index: 2, category: 'Financial' },
  Banking: { index: 3, category: 'Banking' },
  Commerce: { index: 4, category: 'Commerce' },
  E_Reputation: { index: 5, category: 'E_Reputation' },
  Bdf: { index: 6, category: 'Bdf' },
}

export const categoryProviders = [
  { ...providerCategories.Bdf, name: 'bdf' },
  { ...providerCategories.Banking, name: 'budget_insight' },
  { ...providerCategories.Institutional, name: 'infogreffe_court_decision' },
  { ...providerCategories.E_Reputation, name: 'google_news' },
  { ...providerCategories.Financial, name: 'impots_gouv' },
  { ...providerCategories.Financial, name: 'infogreffe' },
  { ...providerCategories.Commerce, name: 'paypal' },
  { ...providerCategories.Banking, name: 'sandbox_openbanking' },
  { ...providerCategories.Institutional, name: 'sandbox_private' },
  { ...providerCategories.E_Reputation, name: 'societe_info' },
  { ...providerCategories.E_Reputation, name: 'webinfo' },
  { ...providerCategories.Commerce, name: 'stripe' },
  { ...providerCategories.Institutional, name: 'sandbox_public' },
  { ...providerCategories.Commerce, name: 'gocardless' },
  { ...providerCategories.Institutional, name: 'company_legal_fr' },
  { ...providerCategories.Institutional, name: 'comply_cube' },
  { ...providerCategories.Commerce, name: 'sandbox_commerce' },
  { ...providerCategories.Commerce, name: 'square' },
  { ...providerCategories.Commerce, name: 'prestashop' },
  { ...providerCategories.Commerce, name: 'woocommerce' },
  { ...providerCategories.Commerce, name: 'shopify' },
]

export const categoryDataTypes = {
  COMPANY_PROFILE: { ...providerCategories.Institutional },
  COMPANY_OFFICER: { ...providerCategories.Institutional },
  CORPORATE_OFFICE: { ...providerCategories.Institutional },
  ACT: { ...providerCategories.Institutional },
  ARTICLES_OF_ASSOCIATION: { ...providerCategories.Institutional },
  AVIS_SIREN: { ...providerCategories.Institutional },
  REGISTRATION_PROOF: { ...providerCategories.Institutional },
  BENEFICIAL_OWNER: { ...providerCategories.Institutional },
  COLLECTIVE_PROCEDURE: { ...providerCategories.Institutional },
  COURT_DECISION: { ...providerCategories.Institutional },
  BACKGROUND_CHECK: { ...providerCategories.Institutional },
  BACKGROUND_CHECK_V2: { ...providerCategories.Institutional },
  BDF_PAYMENT_INCIDENT: { ...providerCategories.Bdf },
  BDF_RATING: { ...providerCategories.Bdf },
  BDF_FINANCES: { ...providerCategories.Bdf },
  BANKING_RELATIONSHIP: { ...providerCategories.Institutional },
  TRADEMARK: { ...providerCategories.Institutional },
  TIMELINE: { ...providerCategories.Institutional },
  FINANCIAL_STATEMENT: { ...providerCategories.Financial },
  TAX_RETURN: { ...providerCategories.Financial },
  TAX_CLEARANCE_CERTIFICATE: { ...providerCategories.Financial },
  TAX_ACCOUNT: { ...providerCategories.Financial },
  TAX_RETURN_ANALYSIS: { ...providerCategories.Financial },
  SECTOR_ANALYSIS: { ...providerCategories.Financial },
  VAT_DECLARATION: { ...providerCategories.Financial },
  CORPORATE_TAX: { ...providerCategories.Financial },
  BANK_ACCOUNT: { ...providerCategories.Banking },
  BANK_TRANSACTION: { ...providerCategories.Banking },
  CASHFLOW: { ...providerCategories.Banking },
  CREDIT_INSIGHTS: { ...providerCategories.Banking },
  COMMERCE_TRANSACTION: { ...providerCategories.Commerce },
  COMMERCE_BALANCE: { ...providerCategories.Commerce },
  COMMERCE_DISPUTE: { ...providerCategories.Commerce },
  COMMERCE_SUBSCRIPTION: { ...providerCategories.Commerce },
  COMMERCE_CUSTOMER: { ...providerCategories.Commerce },
  COMMERCE_LOCATION: { ...providerCategories.Commerce },
  COMMERCE_PRODUCT: { ...providerCategories.Commerce },
  COMMERCE_ORDER: { ...providerCategories.Commerce },
  COMMERCE_TRANSACTION_INSIGHT: { ...providerCategories.Commerce },
  COMMERCE_SUBSCRIPTION_INSIGHT: { ...providerCategories.Commerce },
  COMMERCE_DISPUTE_INSIGHT: { ...providerCategories.Commerce },
  NEWS: { ...providerCategories.E_Reputation },
  CONTACT: { ...providerCategories.E_Reputation },
  WEB_INFO: { ...providerCategories.E_Reputation },
  CONFORMITY_CHECK: { ...providerCategories.Institutional },
}

export const excludeCategoriesForNatural = [
  providerCategories.E_Reputation.category,
  providerCategories.Financial.category,
]

export const excludeProvidersForNatural = [
  'societe_info',
  'infogreffe',
  'infogreffe_court_decision',
  'bdf',
  'sandbox_private',
]

export const QARD_ICON =
  'https://www.qardfinance.com/wp-content/uploads/2018/01/Qard-logo-shadowblue-header-400-100x50.png'

export const Q_ICON =
  'https://www.qardfinance.com/wp-content/uploads/2018/01/Qard-favicon-transparent-mini-300x300.png'

export const STRIPE_ICON =
  'https://upload.wikimedia.org/wikipedia/commons/b/ba/Stripe_Logo%2C_revised_2016.svg'
